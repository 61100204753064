// timeAndSales.tsx
import React, { useEffect, useState } from 'react'
import moment from 'moment'
import tz from 'moment-timezone'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { Pane, Button, Select, TextInput, Spinner, Heading, Text } from 'evergreen-ui'
import { TNSTable } from './tnsComponents'
// CSS Modules, react-datepicker-cssmodules.css
import 'react-datepicker/dist/react-datepicker-cssmodules.css'
import { fetchTNS, getURLsTOBnTrade } from './timeAndSalesHelpers'
const defaultMoment = moment()
    .hours(9)
    .minutes(0)
    .seconds(0)
// TODO: parse params if they exist at initial load
let url_string = window.location.href,
    url = new URL(url_string),
    ymdPart1 = url.searchParams.get('ymdPart'),
    filePart1 = url.searchParams.get('filePart'),
    exchange1 = url.searchParams.get('exchange'),
    ticker1 = url.searchParams.get('ticker'),
    depth1 = url.searchParams.get('depth')

// 'https://time-and-sales.neomantra.net/?ymdPart=YYYY/MM/DD&filePart=YYYYMMDD-HH&exchange=exchange&ticker=ticker&depth=depth'

const TimeAndSales = (props: any) => {
    const [tnsArr, setTNS] = useState([]),
        [url, setURL] = useState(' '),
        [err, setErr] = useState(null),
        [selectedDate, setSelectedDate] = useState(defaultMoment),
        [exchange, setExchange] = useState('gemini'),
        [ticker, setTicker] = useState('BTCUSD'),
        [depth, setDepth] = useState(1.0),
        [isLoading, setIsLoading] = useState(false),
        getTNS = (urlTOB: string, urlTrades: string) => {
            setIsLoading(true)
            fetchTNS(urlTOB, urlTrades)
                .then(data => {
                    // give ID to book column
                    let nextKey = 1
                    data.forEach(x => {
                        x['key'] = ++nextKey
                    })

                    setIsLoading(false)
                    setTNS(data)
                })
                .catch(error => {
                    setIsLoading(false)
                    setErr(error.message)
                })
        },
        onClick = () => {
            let selectedDateUTC = selectedDate.utc(),
                ymdPart = selectedDateUTC.format('YYYY/MM/DD'),
                filePart = selectedDateUTC.format('YYYYMMDD-HH'),
                [urlTOB, urlTrades] = getURLsTOBnTrade(
                    ymdPart,
                    filePart,
                    exchange,
                    ticker,
                    depth.toFixed(2)
                )

            setErr(null)
            setURL(url)
            setTNS([])
            getTNS(urlTOB, urlTrades)
        }
    // useEffect(() => {
    //     console.log(ymdPart1, filePart1, exchange1, ticker1, depth1)
    //     let d = new Date(),
    //         n = d.getTimezoneOffset() / 60,
    //         num = parseInt(filePart1.slice(-2)) - n,
    //         filePShft = ('0' + num).slice(-2),
    //         [urlTOB, urlTrades] = getURLsTOBnTrade(ymdPart1, filePShft, exchange1, ticker1, depth1)

    //     setErr(null)
    //     setURL(url)
    //     setTNS([])
    //     getTNS(urlTOB, urlTrades)
    //     // http://localhost:1234/?ymdPart=2020/11/11&filePart=20201111-09&exchange=gemini&ticker=BTCUSD&depth=1.0
    //     // 'https://time-and-sales.neomantra.net/?ymdPart=YYYY/MM/DD&filePart=YYYYMMDD-HH&exchange=exchange&ticker=ticker&depth=depth'
    // }, []) // Only run on first render

    return (
        <Pane clearfix>
            <Pane clearfix>
                <DatePicker
                    selected={selectedDate.toDate()}
                    onChange={date => {
                        // TODO: daylight savings adjust?
                        var newYork = tz(date, 'America/New_York')
                        setSelectedDate(newYork)
                    }}
                    showTimeSelect
                    maxDate={new Date()}
                    timeFormat='h:mm aa'
                    timeIntervals={60}
                    timeCaption='time'
                    dateFormat='MM / dd / yyyy  hh:mm '
                />
                <Select
                    defaultValue={exchange}
                    onChange={event => {
                        setExchange(event.target.value)
                    }}
                >
                    <option value='binance'>Binance</option>
                    {/* <option value='binanceUS'>Binance US</option> */}
                    <option value='gemini'>Gemini</option>
                </Select>
                <TextInput onChange={event => setTicker(event.target.value)} value={ticker} />
                <TextInput onChange={event => setDepth(event.target.value)} value={depth} />
                <Button
                    onClick={() => {
                        onClick()
                    }}
                >
                    Get T&S
                </Button>
                {isLoading && <Spinner />}
            </Pane>
            <Pane clearfix>
                <Text size={300} color='darkblue'>
                    {url}
                </Text>
            </Pane>
            <Pane clearfix>
                <Heading size={200} color='red'>
                    {err}
                </Heading>
            </Pane>
            <Pane width='100%' height='95%' display='flex'>
                <TNSTable tnsArr={tnsArr} />
            </Pane>
        </Pane>
    )
}

export default TimeAndSales
