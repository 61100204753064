import React from 'react'
import numeral from 'numeral'
import styled from 'styled-components'
import BaseTable, { AutoResizer, Column } from 'react-base-table'
import 'react-base-table/styles.css'

const StyledTable = styled(BaseTable)`
    .BaseTable__row {
        font-family: Arial, Helvetica, sans-serif;
        border: 1px solid black;
        padding: 1px;
    }
`

const makeTime = (sec, tnsec) => {
    let date = new Date(sec * 1000 + tnsec / Math.pow(10, 6)),
        hours = date.getHours(),
        minutes: any = date.getMinutes(),
        seconds: any = date.getSeconds(),
        ampm = hours >= 12 ? 'pm' : 'am',
        ms: any = date.getMilliseconds()
    hours = hours % 12
    hours = hours ? hours : 12 // the hour '0' should be '12'

    minutes = minutes < 10 ? '0' + minutes : minutes
    seconds = seconds < 10 ? '0' + seconds : seconds
    ms = ':' + ms.toString().padStart(3, '0')

    let strTime = hours + ':' + minutes + ':' + seconds + ms + ' ' + ampm
    return strTime
}

const getSideColorStyle = side => ({ color: side == 0 ? 'red' : 'green' })

const getSideUpdateColorStyle = sideUpdated => ({ color: sideUpdated ? 'blue' : 'black' })

const stringRenderer = ({ className, cellData }) => <div className={className}>{cellData}</div>

const numberRenderer2 = ({ className, cellData, rowData }) => (
    <div className={className}>{numeral(cellData).format('0,0.00')}</div>
)

const numberRenderer3 = ({ className, cellData }) => (
    <div className={className}>{numeral(cellData).format('0,0.000')}</div>
)

const timeRenderer = ({ className, cellData }) => <div className={className}>{cellData}</div>

const tickPriceRenderer = ({ className, rowData, cellData }) =>
    !!cellData ? (
        <div style={getSideColorStyle(rowData.side)} className={className}>
            {numeral(cellData).format('0,0.00')}
        </div>
    ) : (
        <div />
    )

const tickSizeRenderer = ({ className, rowData, cellData }) =>
    !!cellData ? (
        <div style={getSideColorStyle(rowData.side)} className={className}>
            {numeral(cellData).format('0,0.000000')}
        </div>
    ) : (
        <div />
    )

const tickIDRenderer = ({ className, rowData, cellData }) =>
    !!cellData ? (
        <div style={getSideColorStyle(rowData.side)} className={className}>
            {cellData}
        </div>
    ) : (
        <div />
    )

const bidPriceRenderer = ({ className, rowData, cellData }) =>
    !!cellData ? (
        <div style={getSideUpdateColorStyle(rowData.bidUpdated)} className={className}>
            {numeral(cellData).format('0,0.00')}
        </div>
    ) : (
        <div />
    )

const askPriceRenderer = ({ className, rowData, cellData }) =>
    !!cellData ? (
        <div style={getSideUpdateColorStyle(rowData.askUpdated)} className={className}>
            {numeral(cellData).format('0,0.00')}
        </div>
    ) : (
        <div />
    )

const bidSizeRenderer = ({ className, rowData, cellData }) =>
    !!cellData ? (
        <div style={getSideUpdateColorStyle(rowData.bidUpdated)} className={className}>
            {numeral(cellData).format('0,0.000000')}
        </div>
    ) : (
        <div />
    )

const askSizeRenderer = ({ className, rowData, cellData }) =>
    !!cellData ? (
        <div style={getSideUpdateColorStyle(rowData.askUpdated)} className={className}>
            {numeral(cellData).format('0,0.000000')}
        </div>
    ) : (
        <div />
    )

const cellRenderersByColumn = [
    timeRenderer, // Time
    tickPriceRenderer, // Trade Price
    tickSizeRenderer, // Trade Size
    tickIDRenderer, // Trade ID
    bidPriceRenderer, // Bid Price
    askPriceRenderer, // Ask Price
    bidSizeRenderer, // Bid Size
    askSizeRenderer, // Ask Size
    bidSizeRenderer, // Bid Depth
    askSizeRenderer, // Ask Depth
    numberRenderer2, // Spread
    stringRenderer, // Ticker
]

const timeGetter = ({ rowData }) => {
    return makeTime(rowData.tsec, rowData.tnsec)
}

const TNSCell = cellProps => {
    const renderer = cellRenderersByColumn[cellProps.columnIndex] || stringRenderer
    return renderer(cellProps)
}

const TNSTable = props => {
    return (
        <AutoResizer>
            {({ width, height }) => (
                <StyledTable
                    data={props.tnsArr}
                    width={width}
                    height={height}
                    rowHeight={30}
                    rowKey={'key'}
                    components={{ TableCell: TNSCell }}
                >
                    <Column title='Time' key='time' width={125} dataGetter={timeGetter} />
                    <Column title='Trade Price' key='price' dataKey='price' width={90} />
                    <Column title='Trade Size' key='volume' dataKey='volume' width={80} />
                    <Column title='Trade ID' key='id' dataKey='id' width={100} />
                    <Column title='Bid Price' key='bidPrice' dataKey='bidPrice' width={80} />
                    <Column title='Ask Price' key='askPrice' dataKey='askPrice' width={80} />
                    <Column title='Bid Size' key='bidVolume' dataKey='bidVolume' width={80} />
                    <Column title='Ask Size' key='askVolume' dataKey='askVolume' width={80} />
                    <Column
                        title='Depth Bid'
                        key='depthBidVolume'
                        dataKey='depthBidVolume'
                        width={80}
                    />
                    <Column
                        title='Depth Ask'
                        key='depthAskVolume'
                        dataKey='depthAskVolume'
                        width={80}
                    />
                    <Column title='Spread' key='spread' dataKey='spread' width={80} />
                    <Column title='Ticker' key='ticker' dataKey='ticker' width={120} />
                </StyledTable>
            )}
        </AutoResizer>
    )
}

export { TNSTable }
