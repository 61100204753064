// PROD or DEBUG
let gcloudHarvesterBase = 'https://viz-test.neomantra.com/gcloud-harvester',
    // const gcloudHarvesterBase = 'http://localhost:4087',
    fetchNResponse = (fetchURL: string) => {
        return fetch(fetchURL).then(response => {
            return response.json()
        })
    },
    fetchTNS = (urlTOB, urlTrades) => {
        let getTNSData = () => {
            return Promise.all([fetchNResponse(urlTrades), fetchNResponse(urlTOB)])
        }

        return getTNSData().then(([topOfBook, trades]) => {
            let allData = [...topOfBook, ...trades]
            allData = allData.sort((a, b) => {
                return a.tsec + a.tnsec / Math.pow(10, 9) - (b.tsec + b.tnsec / Math.pow(10, 9))
            })
            return allData
        })
    },
    getURLsTOBnTrade = (
        ymdPart: string,
        filePart: string,
        exchange: string,
        ticker: string,
        depth: string
    ) => {
        let dateString = `${exchange}/${ymdPart}/${ticker}_${exchange}-${filePart}00.log`,
            urlTOB = `${gcloudHarvesterBase}/topOfBook?depth=${depth}&dateString=${dateString}`,
            urlTrades = `${gcloudHarvesterBase}/trades?dateString=${dateString}`

        return [urlTOB, urlTrades]
    }

export { fetchTNS, getURLsTOBnTrade }
